// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-pages-q-index-tsx": () => import("./../../../src/pages/q/index.tsx" /* webpackChunkName: "component---src-pages-q-index-tsx" */),
  "component---src-pages-results-prismic-mbti-test-result-uid-index-tsx": () => import("./../../../src/pages/results/{PrismicMbtiTestResult.uid}/index.tsx" /* webpackChunkName: "component---src-pages-results-prismic-mbti-test-result-uid-index-tsx" */),
  "component---src-pages-results-prismic-mbti-test-result-uid-view-tsx": () => import("./../../../src/pages/results/{PrismicMbtiTestResult.uid}/view.tsx" /* webpackChunkName: "component---src-pages-results-prismic-mbti-test-result-uid-view-tsx" */)
}

